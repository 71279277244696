import styled from "styled-components";
import { SectionWrapper } from "../../components/wrappers";
import { mobile, tablet } from "../../styles/breakpoints";

export const SectionStyles = styled(SectionWrapper)`
  width: 100%;
  height: 70vh;
  margin: 20px 0;
  margin-top: 60px;
  & > div:first-of-type {
    max-width: 780px;
    width: 100%;
    height: 100%;
    display: grid;
    padding: 0 180px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; // For Internet Explorer and Edge
    scrollbar-width: none; // For Firefox
    @media ${tablet} {
      max-width: 480px;
      padding: 0 0 0 40px;
    }
    @media (max-width: 500px) {
      max-width: 90%;
    }
    & > div {
      margin: auto;
    }
  }
  @media ${mobile} {
    height: 400px;
  }
`;

export const InputBoxStyles = styled.div`
  background: #fdfbfb;
  box-shadow: 0px -30px 30px -20px rgba(34, 34, 34, 0.1);
  padding: 20px 0;
  padding-top: ${({ demoStarted }) => (demoStarted ? "20px" : "0")};
  width: 100%;
  margin-top: 20px;
  svg {
    flex-shrink: 0;
  }
  & > div {
    & > div:first-of-type {
      margin: 0 0 20px;
      @media ${mobile} {
        border-left: none;
      }
      button {
        background: rgba(186, 1, 1, 0.1);
        border-radius: 20px;
        outline: none;
        margin: 5px 0;
        margin-left: 10px;
        border: none;
        font-size: 12px;
        color: #ba0101;
        font-weight: 700;
        padding: 5px 10px;
        cursor: pointer;
        :hover {
          background: rgba(186, 1, 1, 0.3);
          cursor: pointer;
        }
        :disabled {
          background: rgba(186, 1, 1, 0.05);
          cursor: not-allowed;
        }
      }
    }
    & > div:last-of-type {
      & > div {
        border-bottom: 1px solid #ada2a2;
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 10px;
        & > input {
          width: 100%;
          border: none;
          outline: none;
          background: none;
        }
        & > svg {
          cursor: pointer;
          flex-shrink: 0;
        }
      }
    }
  }
`;
