import styled from 'styled-components';

export const SectionWrapper = styled.section`
	height: 100%;
	position: ${({ position }) => position || 'relative'};
	background-color: ${({ backgroundColor }) => backgroundColor || ''};
	background-image: ${({ backgroundImage }) => backgroundImage || ''};
	background-position: ${({ backgroundPosition }) => backgroundPosition || ''};
	background-repeat: ${({ backgroundRepeat }) => backgroundRepeat || ''};
	background-size: ${({ backgroundSize }) => backgroundSize || ''};
`;
