import React from 'react';
import styled from 'styled-components';
import { SectionWrapper, Container, Box, GridWrapper, TextBase } from '../../components/wrappers';
import AnimationVideo from '../../components/AnimationVideo';
import { useQuery } from '../../styles/breakpoints';
import { desktop } from '../../styles/breakpoints';
import TitleTypingEffect from '../../components/TitleTypingEffect';

const HowToStartData = [
	{
		number: 1,
		header: 'Get invite code for privileged access',
		text: 'Join the waitlist to get an invite code. Only a limited amount of clients will be allowed to sign up to ensure quality and proper operation.'
	},
	{
		number: 2,
		header: 'Let me verify your identity',
		text: 'I need this to comply with existing laws and regulations and increase your transaction amount limits.',
	},
	{
		number: 3,
		header: 'Open a bank account, card and crypto wallet',
		text: 'Top up with funds and assets you wish to manage easily, set your thresholds and limits.',
	},
	{
		number: 4,
		header: 'Ask me to do anything!',
		text: 'Yes, all you need is to ask me to perform any action, I may ask additional questions at first to help better understand your intentions and remaining details, and done!',
	},
];

const HowItWorksStyles = styled(SectionWrapper)`
	& > div > div > div:first-of-type {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	h3 {
		font-weight: 700;
		margin-bottom: 50px;
		margin-left: 15px;
	}
`;

const HowToStartBox = styled(GridWrapper)`
	margin: 30px 0;
	& > div:first-of-type {
		color: #FB8989;
		font-size: 64px;
		font-weight: 300;
		margin: 0 auto;	
	}
	& > div:last-of-type {
		& > p:first-of-type {
			color: #000000;
			font-size: 16px;
			font-weight: 700;
			margin-bottom: 10px;
		}
		& > p:last-of-type {
			color: #000000;
			font-size: 16px;
			font-weight: 500;
		}
	}
`

const TitleBox = styled(Box)`
	height: 80px;
	width: 300px;
	margin-bottom: 60px;
	@media ${desktop} {
		margin-bottom: 20px;
		height: 70px;
	}
`

const HowItWorks = () => {
	const { isTablet } = useQuery();
	return (
		<HowItWorksStyles id='how-it-works'>
			<Container>
				<GridWrapper col={2} colL={1}>
					<Box gridRow={isTablet && '2'} margin={isTablet ? '0' : '0 100px 0 0'}>
						<AnimationVideo
							width={'100%'}
							srcSafari={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/landing-how-to-start-phone.mov'}
							srcChrome={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/landing-how-to-start-phone.webm'}
						/>
					</Box>
					<Box>
						<TitleBox>
							<TitleTypingEffect text='how to start' />
						</TitleBox>
						{HowToStartData.map(({ header, text, number }) => (
							<HowToStartBox colExact='50px 1fr' key={header}>
								<Box>{number}</Box>
								<Box>
									<TextBase>
										{header}
									</TextBase>
									<TextBase>
										{text}
									</TextBase>
								</Box>
							</HowToStartBox>
						))}
					</Box>
				</GridWrapper>
			</Container>
		</HowItWorksStyles>
	);
};

export default HowItWorks;
