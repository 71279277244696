import React from 'react';
import styled from 'styled-components';
import { SectionWrapper, Container, Box, GridWrapper } from '../../components/wrappers';
import Accordion from '../../components/Accordion';
import AnimationVideo from '../../components/AnimationVideo';
import { desktop, useQuery } from '../../styles/breakpoints';
import TitleTypingEffect from '../../components/TitleTypingEffect';


const FaqData = [
	{
		question: 'What is ego?',
		answer:
			'Ego is an AI-powered wealth management system, a digital platform that uses artificial intelligence to understand your intention and provide personalized financial management services.',
	},
	{
		question: 'How does ego work?',
		answer:
			'Ego provides a bank account, a payment card and crypto wallet transactions, collects data from the user, such as their financial information, their request history, and uses algorithms to analyze that data and recommend further actions.',
	},
	{
		question: 'What are the benefits of ego?',
		answer:
			'Easy personalized service based on conversational language in a chat app, save time and money while effortlessly managing all your finances in a chat.',
	},
	{
		question: 'Is ego secure?',
		answer:
			'Yes, ego uses advanced security measures to protect user data and investments. The system employs encryption and multi-factor authentication to prevent unauthorized access to user accounts, sensitive actions require additional confirmation.',
	},
	{
		question: 'How does ego compare to traditional finance management services?',
		answer:
			'Ego offers several advantages over traditional financial management services such as all-in-one financial services operated by chat, unified UI, personalized service and automated funds management.',
	},
	{
		question: 'How much does it cost to use an AI-powered wealth management system?',
		answer: 'The cost of ego varies depending on your transaction volume.',
	},
	{
		question: 'How do I get started?',
		answer:
			'To get started with ego, you need to get an ego invite code. Enter the code on the website to start the onboarding process.The system will guide you through the process of setting up your account, collecting your financial information, and creating a personalized list of possibilities, what you can do with ego.',
	},
];

const FaqStyles = styled(SectionWrapper)`
	h3 {
		margin: 30px 0;
		color: #FF4040;
	}	
	& > div >div> div:last-of-type >video:last-of-type {
		position: absolute;
		top: -200px;
		right: -100px;
		z-index: -1;
		width: 100%;
		@media (max-width: 1500px) {
			top: -80px;
			right: 0;
			left: 10px;
		}
	}
`;

const TitleTypingEffectBox = styled(Box)`
	height: 70px;
	width: 100px;
	margin-bottom: 50px;
	@media ${desktop} {
		margin-bottom: 0;
	}
`

const Faq = () => {
	const { isTablet } = useQuery();
	return (
		<FaqStyles id='faq'>
			<Container>
				<GridWrapper col={2} colL={1}>
					<Box margin={isTablet && '0 0 50px'}>
						<TitleTypingEffectBox>
							<TitleTypingEffect text='FAQ' />
						</TitleTypingEffectBox>
						{FaqData.map(({ question, answer }) => (
							<Accordion key={question} title={question}>
								{answer}
							</Accordion>
						))}
					</Box>
					<Box position='relative' margin='0 auto' textAlign='center'>
						<AnimationVideo
							width={'60%'}
							srcSafari={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/man-in-circle-hevc-safari.mov'}
							srcChrome={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/man-in-circle-vp9-chrome.webm'}
						/>
						<AnimationVideo
							width={'100%'}
							srcSafari={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/landing-how-to-start-safari.mov'}
							srcChrome={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/landing-how-to-start-chrome.webm'}
						/>
					</Box>
				</GridWrapper>
			</Container>
		</FaqStyles>
	);
};

export default Faq;
