import React from 'react'
import { Box, SectionWrapper, GridWrapper, Container } from '../components/wrappers'
import styled from 'styled-components'
import AnimationVideo from '../components/AnimationVideo'

const SectionStyles = styled(SectionWrapper)`
    background-color: #FFEAEA;
    margin-top: 155px;
    & > div > div {
        display: flex;
        justify-content: center;
        & > video {
           /* margin-left: auto; */
        }
        & > h1 {
            margin-top: 10px;
            font-size: 9vw;
            color: #FF4040;
            margin-right: 20px;
        }
    }
`

const IAmEgo = () => {
    return (
        <SectionStyles>
            <Container>
                <Box>
                    <h1>Hi, I am</h1>
                    <AnimationVideo width={'40%'} srcSafari={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/i-am-ego-hevc-safari.mov'} srcChrome={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/i-am-ego-chrome.webm'} />
                </Box>
            </Container>
        </SectionStyles>
    )
}

export default IAmEgo
