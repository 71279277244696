import React from 'react';
import { SectionWrapper, Container, Box, GridWrapper, TextBase } from '../../components/wrappers';
import styled from 'styled-components';
import { ReactComponent as Send } from '../../assets/svg/send-message.svg';
import { mobile, tablet } from '../../styles/breakpoints';
import { useQuery } from '../../styles/breakpoints';
import LottieAnimation from '../../components/LottieAnimation';
import animationData from '../../assets/json/joy.json'
import AnimationVideo from '../../components/AnimationVideo';
import TitleTypingEffect from '../../components/TitleTypingEffect';

const FeaturesData = [
	{
		header: 'Manage finances',
		text: 'Send, receive, exchange and transfer fiat or crypto currencies, manage bank transfers, make card payments and control crypto wallets.'
	},
	{
		header: 'Smart spending',
		text: 'Effortlessly analyze, optimize, and personalize your expenses, save recurring actions, create delayed and conditional payments.',
	},
	{
		header: 'Interact with others',
		text: 'Interact with community members directly or in a group chat, discover business opportunities, create fund pools and distribute assets.',
	},
];

const FeaturesStyles = styled(SectionWrapper)`
	
	@media ${tablet} {
		padding-top: 80px;
		margin: 0 auto;
	}
	@media ${mobile} {
		padding-top:40px;
		h2 {
			margin-bottom: 40px;
		}
	}
`;

const FeatureBox = styled(Box)`
	& > div {
		& > p:first-of-type {
			width: fit-content;
			padding: 8px 15px 5px;
			text-transform: uppercase;
			background-color: #FFF6F5;
			border-radius: 66px;
			color: #E32636;
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 10px;
		}
		& > p:last-of-type {
			color: #1e1e1e;
			font-weight: 300;
			font-size: 16px;
		}
	}
`
const TitleBox = styled(Box)`
	height: 72px;

`

const Features = () => {
	const { isTablet } = useQuery();

	return (
		<FeaturesStyles id={'features'}>
			<Container>
				<GridWrapper col={2} colL={1}>
					<Box>
						<TitleBox>
							<TitleTypingEffect text='my features' />
						</TitleBox>
						<Box padding={isTablet ? '20px 0' : '50px 0'}>
							{FeaturesData.map(({ header, text }) => (
								<FeatureBox margin='0 0 40px' maxWidth={!isTablet && '380px'} key={header}>
									<Box>
										<TextBase>
											{header}
										</TextBase>
										<TextBase color='#1E1E1E' fontWeight={300} fontSize='16px'>
											{text}
										</TextBase>
									</Box>
								</FeatureBox>
							))}
						</Box>
					</Box>
					<AnimationBoxStyles >
						<AnimatedVideoStyles srcSafari={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/landing-how-to-start-safari.mov'}
							srcChrome={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/landing-how-to-start-chrome.webm'} />
						<Box width='100px'><LottieAnimation animationData={animationData} /></Box>
						<Box><input disabled placeholder='Type here' /><Send /></Box>
					</AnimationBoxStyles>
				</GridWrapper>
			</Container>
		</FeaturesStyles>
	);
};

export default Features;

const AnimatedVideoStyles = styled(AnimationVideo)`
	position: absolute;
	top: -150px;
	left: 100px;
	width: 80%;
	z-index: -1;
`

const AnimationBoxStyles = styled(Box)`
	position: relative;
	background-color: #FDFBFB;
	max-width: 500px;
	display: flex;
	padding: 10px;
	box-shadow: 0px 0px 30px rgba(34, 34, 34, 0.1);
	border-radius: 20px;
	align-items: center;
	& > div:first-of-type {
		margin-right: 20px;
	}
	& > div:last-of-type {
		display: flex;
		align-items: center;
		height: 35px;
		width: 100%;
		margin-right: 20px;
		border-bottom: 1px solid #ada2a2;
		& > input {
			font-size: 20px;
			background: none;
			outline: none;
			border: none;
			width: 100%;
		}
	}
	@media ${tablet} {
		margin: 100px auto;
	}
`