const initMessages = [
  {
    message: "I want to login", //1 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Enter your email:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "client@ego.com",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Password:",
    comment: "",
    sender: "ego",
    add: false,
  },
  {
    message: "••••••••••••••",
    comment: "",
    sender: "client",
    add: false,
  },
  {
    message: "Logged in successfully", //1 end
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "Show my balance", //2start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Assets", data: "5000 EUR" },
      { title: "Assets", data: "5000 USD" },
      { title: "Assets", data: "22.13 BTC" },
      { title: "Assets", data: "140.32 ETH" },
      { title: "Assets", data: "52.85 USDT" },
    ], //2 end
    comment:
      "ego understands you better than anyone else no matter what language you speak, even with typos and mistakes.",
    sender: "ego",
    add: true,
  },
  {
    message: "shou mai balans", //3start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Assets", data: "5000 EUR" },
      { title: "Assets", data: "5000 USD" },
      { title: "Assets", data: "22.13 BTC" },
      { title: "Assets", data: "140.32 ETH" },
      { title: "Assets", data: "52.85 USDT" },
    ],
    comment:
      "ego understands you better than anyone else no matter what language you speak, even with typos and mistakes.",
    sender: "ego",
    add: true,
  },
  {
    message: "afficher le solde de mon compte",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Assets", data: "5000 EUR" },
      { title: "Assets", data: "5000 USD" },
      { title: "Assets", data: "22.13 BTC" },
      { title: "Assets", data: "140.32 ETH" },
      { title: "Assets", data: "52.85 USDT" },
    ],
    comment:
      "ego understands you better than anyone else no matter what language you speak, even with typos and mistakes.",
    sender: "ego",
    add: true,
  },
  {
    message: "顯示我的餘額",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Assets", data: "5000 EUR" },
      { title: "Assets", data: "5000 USD" },
      { title: "Assets", data: "22.13 BTC" },
      { title: "Assets", data: "140.32 ETH" },
      { title: "Assets", data: "52.85 USDT" },
    ], //3 end
    comment:
      "ego understands you better than anyone else no matter what language you speak, even with typos and mistakes.",
    sender: "ego",
    add: true,
  },
  {
    message: "I want to order a new payment card", //4 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      "Please choose card type:",
      "Virtual Default MasterCard Price: 10 EUR Daily limit: 1000",
      "Virtual Phoenix MasterCard Price: 0 EUR Daily limit: 10000",
      "Physical Gti Gold MasterCard Price: 0 EUR Daily limit: 10000",
      "Physical Gti Platinum MasterCard Price: 0 EUR Daily limit: 10000",
      "Physical Black Limited MasterCard Price: 40 EUR Daily Limit: 10000",
      "Physical Black Limitless MasterCard Price: 0 EUR Daily Limit: 10000",
      "Physical Hills Limited MasterCard Price: 40 EUR Daily Limit: 10000",
      "Physical Hills Limitless MasterCard Price: 0 EUR Daily Limit: 10000",
    ],
    button: true,
    comment: "Select an option from list of cards that can be issued",
    sender: "ego",
    add: true,
  },
  {
    message: [
      "Please choose a shipping address:",
      "Whitton Avenue West,  UB6 0DX London, ENG",
      "Create new",
    ],
    button: true,
    comment: "Select or add your card shipping address",
    sender: "ego",
    add: true,
  },
  {
    message: [
      "Please choose shipping type:",
      "Express. Price: 0 Shipping 1-5 business days",
      "Standard. Price: 0 Shipping: 7 - 27 business days",
    ],
    button: true,
    comment: "Select shipping type",
    sender: "ego",
    add: true,
  },
  // {
  //   message: "Enter verification code:",
  //   button: true,
  //   comment: "",
  //   sender: "ego",
  //   add: true,
  // },
  // {
  //   message: "065654",
  //   button: true,
  //   comment: "",
  //   sender: "client",
  //   add: true,
  // },
  {
    message: "Success",
    button: true,
    comment: "",
    sender: "ego",
    add: true,
  }, //4
  {
    message: "I want to create a new bank account", //5 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      "Please choose a currency for a new bank account:",
      "EUR (SEPA, International) Price: 100 EUR",
      "USD (International) Price: 100 EUR",
      "GBP (International) Price: 100 EUR",
      "CHF (International) Price: 100 EUR",
      "EUR (SEPA, Internal) Price: 100 EUR",
    ],
    button: true,
    comment: "Select from the options",
    sender: "ego",
    add: true,
  },
  // {
  //   message: "Enter verification code:",
  //   comment: "",
  //   sender: "ego",
  //   add: true,
  // },
  // {
  //   message: "858762",
  //   comment: "",
  //   sender: "client",
  //   add: true,
  // },
  {
    message: "Bank account created successfully",
    comment: "",
    sender: "ego",
    add: true, ///5 end
  },
  {
    message: "I want to deposit EUR via SEPA", // 6,7,8 start need to be changed to table
    comment:
      "You can deposit your fiat assets via bank credentials, via card and SEPA",
    sender: "client",
    add: true,
  },
  {
    message: "Amount:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "100",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Amount received:", data: "100 Eur" },
      { title: "Fee:", data: "5.5 Eur" },
      { title: "Total to spend:", data: "105.5 Eur" },
      { title: "IBAN:", data: "LT443050055555001152" },
      { title: "BIC/SWIFT", data: "MOTOLT21" },
      { title: "Beneficiary name:", data: "Swiss Wealth Management AG" },
      { title: "Country:", data: "Switzerland" },
      { title: "Bank name:", data: "Deutche Bank Wealth Management AG" },
      {
        title: "Bank address:",
        data: "Unter den Linden 13-15, 10117 Berlin, Germany",
      },
      { title: "Transfer message:", data: "6TGITB:SWM" },
    ],
    comment: "",
    sender: "ego",
    add: true,
  },
  // {
  //   message: "I want to get internal USD deposit", //9 start
  //   comment:
  //     "You are able to receive internal transfer from another ego user with 0 fees",
  //   sender: "client",
  //   add: true,
  // },
  // {
  //   message: "Amount:",
  //   comment: "",
  //   sender: "ego",
  //   add: true,
  // },
  // {
  //   message: "100",
  //   comment: "",
  //   sender: "client",
  //   add: true,
  // },
  // {
  //   message: [
  //     { title: "Amount received:", data: "100 Eur" },
  //     { title: "Fee:", data: "5.5 Eur" },
  //     { title: "Total to spend:", data: "105.5 Eur" },
  //     { title: "IBAN:", data: "LI59088110102857K001U" },
  //     { title: "BIC/SWIFT", data: "BFRILI22" },
  //     { title: "Beneficiary name:", data: "Bitnuk AG" },
  //     { title: "Beneficiary address:", data: "Metallstrasse 8 Zug 6300" },
  //     { title: "Country:", data: "Switzerland" },
  //     { title: "Bank name:", data: "Bank Frick and Co. AG" },
  //     { title: "Bank address:", data: "Landstrasse 14 Balzers 9496" },
  //     { title: "Transfer message:", data: "6TGITB:SWM" },
  //   ],
  //   comment: "",
  //   sender: "ego",
  //   add: true,
  // },
  {
    message: "Please show me my payment cards", // 10 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      "Your card list:",
      "**8700 Physical card, Status: Active",
      "**8701 Physical card, Status: Pending",
      "**8702 Virtual card,  Status: Active",
      "Add card",
    ],
    button: true,
    comment: "Select from the list to see card info",
    sender: "ego",
    add: true,
  },
  {
    message: [
      { title: "Balance", data: "5000 EUR" },
      { title: "Status", data: "Active" },
      { title: "Card type:", data: "Physical Gti Gold" },
      { title: "Expiration date:", data: "07/27" },
      { title: "ATM withdrawal limit:", data: "O of 5000 EUR" },
      { title: "Spend limit:", data: "O of 10000 EUR" },
    ],
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: ["", "View PIN", "Reveal card info"],
    button: true,
    comment: "Check your card pin code or other card info",
    sender: "ego",
    add: true,
  },
  {
    message: "Enter verification code:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "092338",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Card PIN code: 1234", //10 end
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "I want to turn 2fa on", //11 start
    comment: "You have the ability to secure your account with 2FA",
    sender: "client",
    add: true,
  },
  {
    message: "Enter verification code:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "098998",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "2FA is on", //11 end
    comment: "",
    sender: "ego",
    add: true,
  },
  // {
  //   message: "@ego i want to deposit ETH", //12 start
  //   comment:
  //     "Works for both internal crypto transfers and on-chain blockchain transactions",
  //   sender: "client",
  //   add: true,
  // },
  // {
  //   message: [
  //     {
  //       title: "Network:",
  //       data: "This wallet address only accepts tokens transferred by Ethereum network. Tokens transferred through any other network will be lost.",
  //     },
  //     {
  //       title: "Min amount:",
  //       data: "Min deposit amount is 0.06 ETH. Lower deposit amount will be declined.",
  //     },
  //     {
  //       title: "Fee:",
  //       data: "Deposit fee amount 0.003 ETH.",
  //     },
  //     {
  //       title: "Wallet address type:",
  //       data: "Standard",
  //     },
  //     {
  //       title: "Wallet address:",
  //       data: "0x0F957BEa3a01312a1dc931c48407759e1278ce4Q",
  //     },
  //   ],
  //   comment: "",
  //   sender: "ego",
  //   add: true,
  // },

  {
    message: "I want to swap BTC to ETH", //13 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Amount:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "10",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Exchange rate:", data: "15.1561" },
      { title: "Fee:", data: "0.1 BTC" },
      { title: "Total to spend:", data: "10.1 BTC" },
      { title: "Amount to receive:", data: "151.561 ETH" },
    ],
    confirmExchange: true,
    comment: "Check and confirm if everything is correct",
    sender: "ego",
    add: true,
  },
  {
    message: "Success",
    comment: "",
    sender: "ego",
    add: true,
  }, //13 end
  {
    message: "I want to exchange ETH to USD", //14 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Amount:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "10",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Exchange rate:", data: "1851.85" },
      { title: "Fee:", data: "370.4 USD" },
      { title: "Total to spend:", data: "10 Eth" },
      { title: "Amount to receive:", data: "18518.5 USD" },
    ],
    confirmExchange: true,
    comment: "Check and confirm if everything is correct",
    sender: "ego",
    add: true,
  },
  {
    message: "Success",
    comment: "",
    sender: "ego",
    add: true, //14 end
  },
  {
    message: "I want my girlfriend to be more patient", //16 start
    comment:
      "in case the input message makes no sense or is not related to the functionality - no action is executed by ego",
    sender: "client",
    add: true,
  },
  {
    message: "Could you clarify your question more explicitly?",
    comment: "",
    sender: "ego",
    add: true, //16 end
  },
  {
    message: "I want to get help",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Desired action", data: "Login" },
      { title: "Command", data: "login" },
      { title: "Desired action", data: "Register" },
      { title: "Command", data: "register" },
      { title: "Desired action", data: "Send fiat" },
      { title: "Command", data: "send 10 USD to john@doe.com" },
      { title: "Desired action", data: "Send crypto" },
      { title: "Command", data: "send 0.1 BTC to john@doe.com" },
      { title: "Desired action", data: "Show balances" },
      { title: "Command", data: "show my balance" },
      { title: "Desired action", data: "Exchange fiat" },
      { title: "Command", data: "exchange 100 USD" },
      { title: "Desired action", data: "Exchange crypto" },
      { title: "Command", data: "exchange 0.1 BTC" },
      { title: "Desired action", data: "Top up account" },
      { title: "Command", data: "top up" },
      { title: "Desired action", data: "Add payment card" },
      { title: "Command", data: "issue a card" },
      { title: "Desired action", data: "Card list" },
      { title: "Command", data: "show my cards" },
      { title: "Desired action", data: "Create bank account" },
      { title: "Command", data: "create bank account" },
      { title: "Desired action", data: "Net worth" },
      { title: "Command", data: "my current net worth" },
      { title: "Desired action", data: "Change password" },
      { title: "Command", data: "I want to change my password" },
    ],
    comment: "",
    sender: "ego",
    add: true,
  }, //17??
  {
    message: "I want to send BTC", //18 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Amount:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "3",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Please provide the recipient's wallet address:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Currency:", data: "BTC" },
      { title: "Amount:", data: "3" },
      {
        title: "Recipient's wallet address",
        data: "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
      },
    ],
    confirmTable: true,
    comment: "Check and confirm if everything is correct",
    sender: "ego",
    add: true,
  },
  {
    message: "Enter verification code:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "075754",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Success",
    comment: "",
    sender: "ego",
    add: true, //18 end
  },
  {
    message: "send BTC to the user with ego_id SMDHMC", //19 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Amount:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "2",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Currency:", data: "BTC" },
      { title: "Amount:", data: "2" },
      { title: "User id:", data: "SMDHMC" },
    ],
    confirmTable: true,
    comment: "Check and confirm if everything is correct",
    sender: "ego",
    add: true,
  },
  {
    message: "Enter verification code:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "075754",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Success",
    comment: "",
    sender: "ego",
    add: true, //19 end
  },
  {
    message: "I want to send EUR to the user with ego_id JKNDJA", //20 start
    comment: "Internal transfers to another platform users have zero fees",
    sender: "client",
    add: true,
  },
  {
    message: "Amount:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "1000",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Currency:", data: "EUR" },
      { title: "Amount:", data: "1000" },
      { title: "User id:", data: "JKNDJA" },
    ],
    confirmTable: true,
    comment: "Check and confirm if everything is correct",
    sender: "ego",
    add: true,
  },
  {
    message: "Enter verification code:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "098590",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Transfer successful", //20 end
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "send USD by SWIFT payment", //21 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Amount:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "1000",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Provide recipient full name",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "John Smith",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Bank account number/IBAN",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "GB33BUKB20201555555555",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Currency:", data: "USD" },
      { title: "Transfer method:", data: "Swift" },
      { title: "Amount:", data: "1000" },
      { title: "Recipient full name:", data: "John Smith" },
      { title: "Bank account number/IBAN", data: "GB33BUKB20201555555555" },
    ],
    confirmTable: true,
    comment: "Check and confirm if everything is correct",
    sender: "ego",
    add: true,
  },
  {
    message: "Enter verification code:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "098590",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Transfer successful",
    comment: "",
    sender: "ego",
    add: true,
  }, //21 end
  {
    message: "Send EUR with SEPA credentials", //22 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Amount:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "100",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Provide recipient full name:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "John Smith",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Bank account number/IBAN:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "GB33BUKB20201555555555",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: [
      { title: "Currency:", data: "EUR" },
      { title: "Transfer method:", data: "SEPA" },
      { title: "Amount:", data: "100" },
      { title: "Recipient full name:", data: "John Smith" },
      { title: "Bank account number/IBAN", data: "GB33BUKB20201555555555" },
    ],
    confirmTable: true,
    comment: "Check and confirm if everything is correct",
    sender: "ego",
    add: true,
  },
  {
    message: "Enter verification code:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "098590",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Transfer successful", //22 end
    comment: "",
    sender: "ego",
    add: true,
  },
  // {
  //   message: "What is my networth in EUR", //25 start
  //   comment: "",
  //   sender: "client",
  //   add: true,
  // },
  // {
  //   message: "Your net worth is: 50000 EUR", //25 end
  //   comment: "",
  //   sender: "ego",
  //   add: true,
  // },
  {
    message: "I want to leave a feedback", //26 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Provide a feedback:",
    comment: "",
    sender: "ego",
    add: true,
  },
  {
    message: "ego is awesome",
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "Thank you for your feedback",
    comment: "",
    sender: "ego",
    add: true, //26 end
  },
  {
    message:
      "Book me a hotel in Barcelona for Sonar festival dates & get plane tickets for those days", //27 start
    comment: "",
    sender: "client",
    add: true,
  },
  {
    message: "sorry bro, wait a bit for this feature to be delivered", //27 end
    comment: "",
    sender: "ego",
    add: true,
  },
];

export default initMessages;
