import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, FlexWrapper, TextBase } from '../../components/wrappers';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/svg/input-logo.svg';
import { ReactComponent as Send } from '../../assets/svg/send-message.svg';
import { ReactComponent as Mic } from '../../assets/svg/voice.svg';
import { getFormattedTime } from '../../utils/helpers';
import { SectionStyles, InputBoxStyles } from './styled';
import Message from './Message';
import { MessageStyles } from './Message';
import AnimationVideo from '../../components/AnimationVideo';
import { useQuery } from '../../styles/breakpoints';
import Button from '../../components/buttons/Button'
import { HashLink } from '../../components/links';
import initMessages from './Data';
import InputTypingEffect from '../../components/InputTypeEffect';


const ChatSection = () => {
    const [initialMessages, setInitialMessages] = useState(initMessages);
    const [demoStarted, setDemoStarted] = useState(false)
    const [pause, setPause] = useState(false)
    const [started, setStarted] = useState(false);
    const [messages, setMessages] = useState([]);
    const [value, setValue] = useState('');
    const [clientMessage, setClientMessage] = useState('')
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [isEgoFinished, setIsEgoFinished] = useState(false);
    const [isInputFinished, setIsInputFinished] = useState(false);
    const messagesContainerRef = useRef(null);
    const { isTablet, isMobile } = useQuery();

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    };

    const pauseRef = useRef(pause);

    useEffect(() => {
        pauseRef.current = pause;
    }, [pause]);


    useEffect(() => {
        const timer1 = setTimeout(() => {
            if (!started || pause) return;
            if (currentMessageIndex < initialMessages.length && !pause && isEgoFinished) {
                if (initialMessages[currentMessageIndex].sender === 'ego' && !pause) {
                    setIsEgoFinished(false);
                    setMessages((prevMessages) => [...prevMessages, initialMessages[currentMessageIndex]]);
                    setCurrentMessageIndex(currentMessageIndex + 1);
                    setIsInputFinished(false);
                }
                if (isEgoFinished && initialMessages[currentMessageIndex].sender === 'client' && !pause) {
                    setClientMessage(initialMessages[currentMessageIndex].message);
                    setIsInputFinished(false);
                    if (!pause) {
                        if (isInputFinished && initialMessages[currentMessageIndex].sender === 'client') {
                            if (initialMessages[currentMessageIndex].add) {
                                setMessages((prevMessages) => [
                                    ...prevMessages,
                                    initialMessages[currentMessageIndex],
                                ]);

                            }
                            setCurrentMessageIndex(currentMessageIndex + 1);
                            setClientMessage('');
                            setIsInputFinished(false);
                        }
                    }
                }
            }
        }, 1200);
        if (initialMessages.length === currentMessageIndex) {
            setStarted(false)
        }
        const timer2 = setTimeout(() => {
            scrollToBottom();
        }, 10);

        return () => {
            if (pause) {
                clearTimeout(timer1);
                clearTimeout(timer2);
            }
            clearTimeout(timer1);
            clearTimeout(timer2);
        };

    }, [pause, isEgoFinished, messages, isInputFinished, started, initialMessages, currentMessageIndex]);

    const handlePause = (e) => {
        e.preventDefault()
        setPause((prevPause) => !prevPause);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value.length !== 0) {
            e.preventDefault()
            setDemoStarted(false)
            setValue('')
            setCurrentMessageIndex(0)
            setMessages([])
        }
    };
    const handleStartChat = (e) => {
        e.preventDefault()
        setStarted(true);
        setIsEgoFinished(true)
        setValue('')
        setDemoStarted(true)
        setCurrentMessageIndex(0)
        setMessages([])
        setPause(false)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleStopChat = (e) => {
        e.preventDefault()
        setPause((prevPause) => !prevPause);
        setIsEgoFinished(false)
        setStarted(false)
        // setStarted(false)
        setPause(true)
        setDemoStarted(false)
        setValue('')
        setClientMessage('')
        setMessages([])
        setCurrentMessageIndex(0)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // setIsEgoFinished(false)
        // setIsInputFinished(false)
    }


    return (
        <SectionStyles>
            <Container ref={messagesContainerRef}>
                <Box ref={messagesContainerRef}>

                    <>
                        <FirstMessageStyles>
                            <Box>{getFormattedTime()}</Box>
                            <Logo width='20px' />
                            <TextBase textAlign='center' width={isMobile ? '100%' : '400px'} fontWeight={900} color='#FF4040' fontSize={isMobile ? '60px' : '90px'}>
                                Hi, I am
                            </TextBase>
                            <VideoWrapper>
                                <InnerVideo width={'100%'} srcSafari={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/i-am-ego-hevc-safari.mov'} srcChrome={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/i-am-ego-chrome.webm'} />
                            </VideoWrapper>
                        </FirstMessageStyles>
                        <MessageStyles>
                            <Box>{getFormattedTime()}</Box>
                            <Logo width='20px' />
                            <TextBase fontSize={isTablet ? '16px' : '20px'}>
                                an AI-powered super-app, that can actually do things for you.
                                <br />
                                <br />
                                I understand natural language and transform it into chains of commands to connected services.
                                <br />
                                <br />
                            </TextBase>
                            <Box margin='10px 0 0'>
                                <HashLink margin='0 10px 0 0' to='/#contact'><Button>Sign Up</Button></HashLink>
                                <Button onClick={handleStartChat}>Explore features</Button>
                            </Box>
                        </MessageStyles>
                    </>
                    <>
                        {messages.map(({ message, sender, comment, confirmTable, button, confirmExchange }, i) => (
                            <Message
                                key={i}
                                finished={isEgoFinished}
                                setFinished={setIsEgoFinished}
                                time={getFormattedTime()}
                                message={message}
                                sender={sender}
                                comment={comment}
                                pause={pause}
                                confirmTable={confirmTable}
                                button={button}
                                confirmExchange={confirmExchange}
                            />
                        ))}
                    </>
                </Box>
            </Container>
            <InputBoxStyles demoStarted={demoStarted} padding='0 20px'>
                <Box maxWidth='480px' padding={'0 20px'} margin='0 auto'>
                    <InputTopBox demoStarted={demoStarted}>
                        <button style={{ width: '70px' }} disabled={!started} onClick={handlePause}>{pause ? 'Resume' : 'Pause'}</button>
                        <button style={{ width: '70px' }} onClick={started ? handleStopChat : handleStartChat}>{started ? 'Stop' : 'Start'}</button>
                    </InputTopBox>
                    {demoStarted && <FlexWrapper width='100%'>
                        <Logo width={'40'} height='40px' />
                        <Box>
                            {clientMessage.length === 0 ? (
                                <input disabled onKeyDown={handleInputKeyDown} value={value} onChange={(e) => setValue(e.target.value)} placeholder='Start Chatting' />
                            ) : (
                                <Box width='100%' maxWidth='300px'><InputTypingEffect pause={pause} finished={isInputFinished} text={clientMessage} setFinished={setIsInputFinished} /></Box>
                            )}
                            <Send />
                        </Box>
                        <Mic width={'60px'} />
                    </FlexWrapper>}
                </Box>
            </InputBoxStyles>
        </SectionStyles >
    );
};

export default ChatSection;

const InputTopBox = styled(Box)`
	border-left: 1px solid black;
	transition: height 0.5s;
	height: ${({ demoStarted }) => demoStarted ? '35px' : '0'};
	visibility: ${({ demoStarted }) => !demoStarted ? 'hidden' : 'visible'};
`
// const TypeEffectStyles = styled(InputTypingEffect)`
// 	width: 90%;
// 	flex-grow: 0;
//     max-width: 325px;
//     overflow-x: hidden;
//     font-size: 14px;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     /* direction: rtl;
//     text-align: left; */
// 	@media ${mobile} {
// 		max-width: 52vw;
// 	}
// `
const FirstMessageStyles = styled(MessageStyles)`
	& > div:first-of-type {
		left: -42px;
		font-size: 14px;
	}
`
const VideoWrapper = styled.div`
	position: relative;
	height: 0;
	padding-top: calc(100% / (291 / 108)); /* 16:9 aspect ratio */
`

const InnerVideo = styled(AnimationVideo)`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
`
