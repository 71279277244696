import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { desktop, mobile } from '../styles/breakpoints';

const TitleTypingEffect = ({ text }) => {
    const [typingText, setTypingText] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [finishedTyping, setFinishedTyping] = useState(false);
    const ref = useRef(null);
    const timerIdRef = useRef(null);

    const startTyping = useCallback(() => {
        if (typeof text !== 'string') {
            setTypingText(text);
            setFinishedTyping(true);
            return;
        }
        setTimeout(() => {
            timerIdRef.current = setInterval(() => {
                if (typingText.length < text.length) {
                    setTypingText((prevText) => prevText + text.charAt(prevText.length));
                } else {
                    clearInterval(timerIdRef.current);
                }
            }, 60);
        }, 2000); // 2-second delay

        return () => clearInterval(timerIdRef.current);
    }, [text, typingText]);

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !isTyping) {
                    setIsTyping(true);
                    startTyping();
                    observer.unobserve(ref.current);
                }
            },
            { threshold: 0.5 },
        );

        observer.observe(currentRef);

        return () => {
            observer.unobserve(currentRef);
        };
    }, [isTyping, startTyping]);

    useEffect(() => {
        if (typingText === text) {
            setFinishedTyping(true);
        }
    }, [typingText, text]);

    if (!text.length) return <h3 aria-label="Empty heading"> </h3>;

    return <H3Styles isTyping={isTyping} finishedTyping={finishedTyping} ref={ref}>{typingText}<span>|</span></H3Styles>;
};

export default TitleTypingEffect;

const H3Styles = styled.h3`
    width: fit-content;
    height: 72px;
    color: #FF4040;
    position: relative;
    @media ${desktop} {
        margin-bottom: 70px;
        height: 50px;
    }
    @media ${mobile} {
        font-size: 24px;
        height: 38px;
    }
    span {
        animation: ${({ finishedTyping }) => finishedTyping ? 'blinkEnd linear 1.5s forwards' : 'blink2 linear 1s infinite forwards'};
        font-size: 28px;
        position: absolute;
        right: ${({ finishedTyping }) => finishedTyping ? '-20px' : '-5px'};
        top: 50%;
        transform: translateY(-50%);
        @media ${desktop} {
            font-size: 16px;
            right: ${({ finishedTyping }) => finishedTyping ? '-10px' : '-5px'};
        }
        @keyframes blink2 {
            0% {
                opacity: 1;
            }
            25% {
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            75% {
                opacity: 0;
            }  
            100% {
                opacity: 1;
            }
        }
        @keyframes blinkEnd {
            0% {
                opacity: 0;
            }
            15% {
                opacity: 1;
            }
            30% {
                opacity: 0;
            }
            45% {
                opacity: 1;
            }  
            60% {
                opacity: 0;
            }
            75% {
                opacity: 1;
            }
            90% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
`
