import styled from 'styled-components';
import { desktop, mobile } from '../../styles/breakpoints';
export const TextBase = styled.p`
	margin: ${({ margin }) => margin || ''};
	padding: ${({ padding }) => padding || ''};
	font-size: ${({ fontSize }) => fontSize || '24px'};
	line-height: ${({ lineHeight }) => lineHeight || ''};
	font-weight: ${({ fontWeight }) => fontWeight || 400};
	color: ${({ color }) => color || '#222222'};
	text-align: ${({ textAlign }) => textAlign || ''};
	text-decoration: ${({ textDecoration }) => textDecoration || ''};
	text-transform: ${({ textTransform }) => textTransform || ''};
	opacity: ${({ opacity }) => opacity || ''};
	width: ${({ width }) => width || ''};
	width: ${({ maxWidth }) => maxWidth || ''};
	@media ${desktop} {
		font-size: ${({ fontSize }) => fontSize || '20px'};
	}
	@media ${mobile} {
		font-size: ${({ fontSize }) => fontSize || '16px'};
	}
`;
