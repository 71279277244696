import { useState, useEffect } from 'react';
import { Box, TextBase } from './wrappers';

const TypingEffect = ({ text, setFinished, pause, className }) => {
	const [index, setIndex] = useState(0);
	const [typingText, setTypingText] = useState('');

	useEffect(() => {
		if (typeof text !== 'string') {
			setTypingText(text);
			setFinished(true);
			return;
		}

		if (pause) return;

		const timerId = setInterval(() => {
			if (index < text.length) {
				setTypingText((prevText) => prevText + text.charAt(index));
				setIndex((prevIndex) => prevIndex + 1);
			}
			else {
				setFinished(true);
				clearInterval(timerId);
			}
			if (index < text.length) {
				setFinished(false)
			}
		}, 24);

		return () => clearInterval(timerId);

	}, [index, text, pause]);

	useEffect(() => {
		if (!pause) {
			setFinished(false);
		}
	}, [pause]);

	if (!text.length) return <p></p>;

	return <p className={className}>{typingText}</p>;
};

export default TypingEffect;
