import React from 'react';
import styled from 'styled-components';
import { SectionWrapper, Box, TextBase } from '../../components/wrappers';
import { mobile, tablet } from '../../styles/breakpoints';
import { useQuery } from '../../styles/breakpoints';
import SubscribeForm from '../../components/SubscribeForm';
import AnimationVideo from '../../components/AnimationVideo';
import TitleTypingEffect from '../../components/TitleTypingEffect';
import { TypingEffectBox } from './SighUp';

const SignUpStyles = styled(SectionWrapper)`
    position: relative;
	padding: 50px 0 100px;
	margin: 0 auto;
    width: 100%;
    margin-top: 200px;
    
	h3 {
		margin-bottom: 60px;
	}

	@media ${tablet} {
		margin-top: 150px;
		padding: 50px 0;
        padding-bottom: 0;
		h3 {
			margin-bottom: 30px;
		}
	}
	@media ${mobile} {
		margin-top: 130px;
        padding-bottom: 0;
	}
`;

const AnimatedVideoStyles = styled(AnimationVideo)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-200%, -30%);
    width: 350px;
    @media (max-width:1550px){
       width: 300px;
    }
`


const TopSighUp = () => {
    const { isMobile, isTablet, isDesktop } = useQuery();

    return (
        <SignUpStyles id='about-ego'>
            <Box id='contact'>
                <TypingEffectBox>
                    <TitleTypingEffect text='Join the waitlist to access ego' />
                </TypingEffectBox>
                <TextBase textAlign='center' color='#000000' margin={isTablet ? '0 10px 10px 10px' : '0 0 10px -180px'} fontSize={isMobile ? '14px' : '16px'}>You will receive an invite code to try out ego features</TextBase>
                <SubscribeForm />
            </Box>
            {!isDesktop && <AnimatedVideoStyles
                srcSafari={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/landing-how-to-start-safari.mov'}
                srcChrome={'https://ego-media.s3.eu-central-1.amazonaws.com/videos/landing-videos/landing-how-to-start-chrome.webm'}
            />}
        </SignUpStyles>
    );
};

export default TopSighUp;
