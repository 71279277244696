export const getFormattedTime = (time) => {
  const options = {
    hour12: false, // Set to false to remove AM/PM
    timeStyle: "short",
  };
  return (time ? new Date(time) : new Date()).toLocaleTimeString(
    undefined,
    options
  );
};
