import styled from 'styled-components';
import { mobile } from '../../styles/breakpoints';

const BaseButton = styled.button`
	margin: ${({ margin }) => margin || ''};
	padding: 6px 24px;
	color: #ffffff;
	border-radius: 20px;
	border: none;
	appearance: none;
	box-shadow: none;
	white-space: nowrap;
	/* font-weight: 400; */
	font-size: 16px;
	transition: all 0.25s ease-out;
	background: ${({ backgroundColor }) => backgroundColor || '#BA0101'};
	/* backdrop-filter: blur(15px); */
	:hover {
		background-color: rgba(186,1,1, 0.8);
		cursor: pointer;
	}
	:disabled {
		background: rgba(0, 0, 0, 0.3);
		cursor: not-allowed;
	}
	@media ${mobile} {
		padding: 6px 20px;
	}
`;

const PrimaryButton = styled(BaseButton)`
	background: var(--special);
	color: var(--text);
	border: 1px solid var(--special);
	:hover {
		background-color: #2e91a0;
	}
`;

const SecondaryButton = styled(BaseButton)`
	color: #35a6b6;
	border: 1px solid #35a6b6;
	:hover {
		background: rgba(0, 0, 0, 0.5);
	}
`;
const DarkButton = styled(BaseButton)`
	color: var(--text);
	background-color: #001b26;
	:hover {
		background-color: rgba(0, 0, 0, 0.5);
	}
`;

const Button = ({ variant = 'base', children, ...rest }) => {
	if (variant === 'primary') return <PrimaryButton {...rest}>{children}</PrimaryButton>;
	if (variant === 'secondary') return <SecondaryButton {...rest}>{children}</SecondaryButton>;
	if (variant === 'dark') return <DarkButton {...rest}>{children}</DarkButton>;
	return <BaseButton {...rest}>{children}</BaseButton>;
};
export default Button;
