import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Box, TextBase } from '../../components/wrappers';
import { ReactComponent as Logo } from '../../assets/svg/input-logo.svg';
import { mobile } from '../../styles/breakpoints';
import Tooltip from '../../components/Tooltip';
import TypingEffect from '../../components/TypingEffect';
import { ReactComponent as RedTick } from '../../assets/svg/select-tick.svg'

export const MessageStyles = styled.div`
	width: 95%;
	position: relative;
	padding: 0 20px;
	margin: 20px 0;
	border-left: 1px solid #222222;
	word-wrap: break-word;
	color: #998D8D;
	/* font-size: 20px; */
	& > div:first-of-type {
		position: absolute;
		top: 5px;
		left: -45px;
		
		@media ${mobile} {
			left: -40px;
		}
	}
	
	& > div:last-of-type {
		color: #222222;
		display: block;
		width: 400px;
		font-size: 20px;
		@media ${mobile} {
			font-size: 15px;
			width: 70vw;
		}
	}
	
`;

const Message = ({ time, message, comment, finished, setFinished, sender, pause, confirmTable, button, confirmExchange }) => {
	useEffect(() => {
		if (sender === 'ego') {
			setFinished(true);
		}
	}, [confirmTable, button, message, sender, setFinished]);

	const handleMessage = () => {
		if (sender === 'ego') {
			if (typeof message === 'string') {
				return <TypingEffect pause={pause} finished={finished} setFinished={setFinished} sender={sender} text={message} />
			}
			else if (button && Array.isArray(message)) {
				return <ButtonsMessage>
					{message.map((item, index) => (
						<div key={index}>
							<div>{item}</div>
							{index === 1 && <RedTick />}
						</div>
					))}
				</ButtonsMessage>

			}
			else if (confirmExchange) {
				return <ConfirmExchangeTable>{message.map(({ title, data }, i) => <div key={i}>
					<div>{title}</div>
					<div>{data}</div>
				</div>)}
					<div>
						<div>Confirm</div>
						<div>Cancel</div>
					</div>
					{/* {setFinished(true)} */}
				</ConfirmExchangeTable>
			}
			else if (confirmTable) {
				return <ConfirmTable>{message.map(({ title, data }, i) => <div key={i}>
					<div>{title}</div>
					<div>{data}</div>
				</div>)}
					<div>
						<div>Confirm</div>
						<div>Cancel</div>
					</div>
					{/* {setFinished(true)} */}
				</ConfirmTable>
			}
			else {
				return <Table>{message.map(({ title, data }, i) => <TableStyles key={i}>
					<div>{title}</div>
					<div>{data}</div>
				</TableStyles>)}
				</Table>
			}
		} else {
			return <div style={{ width: '95%' }}>{message}</div>
		}
	}
	return (
		<>{comment.length > 0 ? <Tooltip tooltip={comment}>
			<MessageStyles>
				<Box>{time}</Box>
				<Box>{sender === 'ego' ? <Logo width='20px' /> : <TextBase color='#998D8D' fontSize='14px' margin='0 0 10px 0'>{sender}</TextBase>}</Box>
				<Box>
					{handleMessage()}
				</Box>
			</MessageStyles>
		</Tooltip> : <MessageStyles>
			<Box>{time}</Box>
			{sender === 'ego' ? <Logo width='20px' /> : sender}
			<Box>
				{handleMessage()}
			</Box>
		</MessageStyles>}
		</>
	);
};

export default Message;


const Table = styled.div`
	& > div:nth-of-type(odd) {
		background-color: rgb(216 216 216);
		border-radius: 8px;
	}	
	width: 95%;
`

const TableStyles = styled.div`
	padding: 5px 10px;
	& > div:first-of-type {
		font-size: 12px;
		color: rgb(119, 119, 119);
	}
	& > div:last-of-type {
		font-size: 16px;
		overflow-x: hidden;
	}
`
const ButtonsMessage = styled.div`
	width: 95%;
	& > div {
		position: relative;
	}
	& > div:not(:first-child) {
		border-radius: 0;
		border-bottom: 2px solid var(--red);
		color: #222222;
		/* border-radius: 8px; */
		font-size: 16px;
		padding: 5px 20px 5px 0;
		margin: 5px 0;
		@media ${mobile} {
			padding: 5px 30px 5px 0;
		}
	}
	& > div > svg {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}	
`
const ConfirmExchangeTable = styled.div`
	width: 95%;
	& > div:not(:last-child) {
		padding: 5px 10px;
		:nth-child(odd){
			background-color: rgb(216 216 216);
			border-radius: 8px;
		}
		& > div:first-child {
			font-size: 12px;
			color: rgb(119, 119, 119);
		}
		& > div:last-child {
			font-size: 16px;
		}
	}	
	& > div:last-child {
		display: flex;
		justify-content: end;
		& > div {
			margin: 10px 5px;
			padding: 5px 15px;
			border-radius: 8px;
			font-size: 14px;
			background-color: #BA0101;
			:first-of-type {
				color: white;
			}
			:last-of-type {
				border: 1px solid var(--red);
				background-color: white;
				color: var(--red);
				margin-right: 0;
			}
		} 
	}
`

const ConfirmTable = styled.div`
	width: 95%;
	& > div:not(:last-of-type) {
		width: 100%;
		border-bottom: 1px solid black;
		padding: 5px 10px;
		& > div:first-of-type {
			font-weight: 700;
			font-size: 18px;
		}
		& > div:last-of-type {
			font-size: 18px;
		}
	}
	& > div:last-of-type {
		width: 100%;
		display: flex;
		justify-content: end;
		& > div {
			margin: 10px 5px;
			padding: 5px 15px;
			border-radius: 8px;
			font-size: 14px;
			background-color: #BA0101;
			:first-of-type {
				color: white;
			}
			:last-of-type {
				border: 1px solid var(--red);
				background-color: white;
				color: var(--red);
				margin-right: 0;
			}
		} 
	}
`
