import styled from 'styled-components';
import { Box } from './Box';

export const FlexWrapper = styled(Box)`
	display: ${({ display }) => display || 'flex'};
	justify-content: ${({ justifyContent }) => justifyContent || ''};
	align-items: ${({ alignItems }) => alignItems || ''};
	gap: ${({ gap }) => gap || ''};
	flex-direction: ${({ flexDirection }) => flexDirection || ''};
	width: ${({ width }) => width || ''};
	max-width: ${({ maxWidth }) => maxWidth || ''};
	height: ${({ height }) => height || ''};
	flex-wrap: ${({ flexWrap }) => flexWrap || ''};
`;
