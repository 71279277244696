import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GlobalStyle } from './styles/style';
import Main from './pages/Main';
import NotFound from './pages/NotFound/NotFound';
function App() {
	return (
		<>
			<GlobalStyle />
			<Router>
				<Routes>
					<Route path='/' element={<Main />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
