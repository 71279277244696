import React from 'react';
import Navbar from '../components/navigation/Navbar';
// import Hero from './Hero/Hero';
import Features from './Features/Features';
import HowItWorks from './HowItWorks/HowItWorks';
import Faq from './FAQ/Faq';
import SighUp from './SignUp/SighUp';
import Footer from '../components/footer/Footer';
import ChatSection from './Chat/ChatSection';
import TopSighUp from './SignUp/TopSighUp';
import IAmEgo from './IAmEgo';

const Main = () => {
	return (
		<>
			<Navbar />
			<ChatSection />
			<TopSighUp />
			{/* <IAmEgo /> */}
			{/* <Hero /> */}
			<Features />
			<HowItWorks />
			<Faq />
			<SighUp />
			<Footer />
		</>
	);
};

export default Main;
