import styled from 'styled-components';

export const Box = styled.div`
	margin: ${({ margin }) => margin || ''};
	padding: ${({ padding }) => padding || ''};
	border: ${({ border }) => border || ''};
	text-align: ${({ textAlign }) => textAlign || ''};
	border-radius: ${({ borderRadius }) => borderRadius || ''};
	position: ${({ position }) => position || ''};
	box-shadow: ${({ boxShadow }) => boxShadow || ''};
	width: ${({ width }) => width || ''};
	height: ${({ height }) => height || ''};
	max-width: ${({ maxWidth }) => maxWidth || ''};
	min-height: ${({ minHeight }) => minHeight || ''};
	grid-row: ${({ gridRow }) => gridRow || ''};
	display: ${({ display }) => display || ''};
	background: ${({background})=> background || ''};
`;
