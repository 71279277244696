import styled from 'styled-components';
import { tablet } from '../styles/breakpoints';
const TooltipStyles = styled.div`
	position: relative;
	width: fit-content;
	box-sizing: border-box;

	::before,
	::after {
		--scale: 1;
		--arrow-size: 20px;
		--tooltip-color: #ffeaea;
		position: absolute;
		box-sizing: border-box;
		max-width: 180px;
		z-index: 1000;
		top: 50%;
		left: 90%;
		transform: translateX(var(--translate-x, 0)) translateY(-50%) scale(var(--scale));
		transition: 150ms transform;
		transform-origin: center left;
		@media ${tablet} {
			--scale: 0;
		}
	}

	::before {
		--translate-x: calc(var(--arrow-size));
		content: ${({ tooltip }) => JSON.stringify(tooltip)};
		color: #222222;
		padding: 0.8rem;
		border-radius: 0.6rem;
		text-align: center;
		width: max-content;
		background: var(--tooltip-color);
	}
/* 
	:hover::before,
	:hover::after {
		--scale: 1;
	} */

	::after {
		--translate-x: -20%;
		content: '';
		border: var(--arrow-size) solid transparent;
		border-right-color: var(--tooltip-color);
		transform-origin: left;
	}
`;

const Tooltip = ({ tooltip, children }) => {
	return <TooltipStyles tooltip={tooltip}>{children}</TooltipStyles>;
};

export default Tooltip;
