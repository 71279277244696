import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { HashLink, SimpleLink } from '../links';
import { ReactComponent as Logo } from '../../assets/svg/landing-logo.svg';
import { ReactComponent as MobileHandlerClose } from '../../assets/svg/close.svg';
import { ReactComponent as MobileHandler } from '../../assets/svg/mobile-hamburger.svg';
import Button from '../buttons/Button';
import { linksData } from './Navbar';

const MobileNavbarWrap = styled.section`
	position: fixed;
	transition: all 0.5s;
	background: ${({ open }) => (open ? 'rgba(253, 251, 251)' : 'rgba(253, 251, 251, 0.7)')};
	z-index: 99;
	color: var(--red);
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
	& > svg:last-of-type {
		path {
			fill: black;
		}
	}
	& > a > svg {
		width: auto;
	}
`;
const MobileNavbar = styled.nav`
	background: rgba(253, 251, 251);
	position: fixed;
	width: 100%;
	z-index: 98;
	top: 0;
	right: 0;
	transition: all 0.5s;
	padding-bottom: 10px;
	${({ open }) =>
		open
			? `
        transform: translateY(50px);
    `
			: `
        transform: translateY(-100%);
    `}
	& > a {
		text-align: center;
		display: block;
		padding: 10px 20px;
		font-weight: 500;
		color: var(--red);
	}
`;
const MobWrap = styled.div`
	overflow-x: hidden;
`;

const MobNavbar = () => {
	const [open, setOpen] = useState(false);
	const [isScrollStyle, setIsScrollStyle] = useState(false);

	const node = useRef();

	const clickOutside = (e) => {
		if (node.current.contains(e.target)) {
			return;
		}
		setOpen(false);
	};

	useEffect(() => {
		document.addEventListener('mousedown', clickOutside);
		const handleScroll = () => {
			setIsScrollStyle(window.scrollY > 200);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			document.removeEventListener('mousedown', clickOutside);
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<MobWrap>
				<MobileNavbarWrap open={open} isScrollStyle={isScrollStyle}>
					<HashLink to='/#hero'>
						<Logo />
					</HashLink>
					{open ? <MobileHandlerClose height='20px' onClick={() => setOpen(!open)} /> : <MobileHandler onClick={() => setOpen(!open)} />}
				</MobileNavbarWrap>
				<MobileNavbar open={open} ref={node} isScrollStyle={isScrollStyle}>
					{linksData.map(({ text, to }) => (
						<HashLink padding='10px 20px' key={to} to={to}>
							{text}
						</HashLink>
					))}
				</MobileNavbar>
			</MobWrap>
		</>
	);
};
export default MobNavbar;
