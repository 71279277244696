import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as Mail } from '../assets/svg/mail.svg';
import styled from 'styled-components';
import { mobile, tablet } from '../styles/breakpoints';
import Button from './buttons/Button';
import { Box, FlexWrapper, TextBase } from './wrappers';
// import { post } from '../utils/api';

const FormStyles = styled.form`
	display: flex;
	align-items: center;
	justify-content: center;
	& > div {
		max-width: 440px;
		width: 100%;
	}
	& > div > div:first-of-type {
		background-color: #FFF6F5;
		border-radius: 15px;
		padding: 8px 8px 4px 8px;
		margin: 5px 5px 0 0;
		
	}
	& > div > div:last-of-type {
		position: relative;
		width: 100%;
		/* max-width: 400px; */
		& > input {
			padding: 8px 0 6px;
			width: 100%;
			background: none;
			outline: none;
			border: none;
			color: var(--text);
			border-bottom: 2px solid #999999;
			margin-right: 10px;
			border-color: ${({ error }) => error && 'var(--red)'};
			border-radius: 0;
			:focus,
			:hover {
				border-color: #757575;
				outline: none;
			}
		}
		& > p:first-of-type {
			position: absolute;
			bottom: -25px;
			color: var(--red);
			visibility: ${({ error }) => (error ? 'visible' : 'hidden')};
			@media ${mobile} {
				font-size: 12px;
				bottom: -20px;
			}
		}
		& > p:last-of-type {
			position: absolute;
			bottom: -25px;
			
			/* color: ${({ error }) => error && 'var(--red)'}; */
			visibility: ${({ response }) => (response ? 'visible' : 'hidden')};
			@media ${mobile} {
				font-size: 12px;
				bottom: -20px;
			}
		}
	}
	@media ${tablet} {
		margin: 20px;
	}
	@media ${mobile} {
		flex-direction: column;
		button {
			margin-top: 25px;
		}
	}
`;

const SubscribeForm = () => {
	const emailRef = useRef()
	const [email, setEmail] = useState('');
	const [response, setResponse] = useState('');
	const [error, setError] = useState('');

	useEffect(() => {
		function handleMouseOver() {
			emailRef.current.focus();
		}

		emailRef.current.addEventListener('mouseover', handleMouseOver);

		// Clean up the event listener when the component unmounts
		return () => {
			emailRef.current.removeEventListener('mouseover', handleMouseOver);
		};
	}, []);

	const handleSubscribe = async (e) => {
		e.preventDefault();

		if (email === '') {
			setResponse('');
			setError('Enter your email');
		} else if (!email.match(/[^@\s]+@[^@\s]+\.[^@\s]+/)) {
			setResponse('');
			setError('');
			setError('Email format is not correct');
		} else {
			window.klaviyo.identify({
				$email: email,
			});
			setError('');
			setResponse('Congratulations! Your email was added to ego wishlist');
			setEmail('');
		}
	};


	return (
		<FormStyles error={error} response={response} onSubmit={handleSubscribe}>
			<FlexWrapper>
				<Box>
					<Mail />
				</Box>
				<Box>
					<input ref={emailRef} value={email} onChange={(e) => setEmail(e.target.value)} placeholder={'Enter your email'} />
					<TextBase fontSize='14px'>{error}</TextBase>
					<TextBase fontSize='14px'>{response}</TextBase>
				</Box>
			</FlexWrapper>
			<Button>Join waitlist</Button>
		</FormStyles>
	);
};

export default SubscribeForm;
