import React from 'react';
import styled from 'styled-components';
import { Container, Box, GridWrapper, TextBase, FlexWrapper } from '../../components/wrappers';
import { ReactComponent as Logo } from '../../assets/svg/landing-logo.svg';
import { HashLink, SimpleLink } from '../links';
import { ReactComponent as Facebook } from '../../assets/svg/socialmedia/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/svg/socialmedia/instagram.svg';
import { ReactComponent as LinkedIn } from '../../assets/svg/socialmedia/linkedin.svg';
import { ReactComponent as Twitter } from '../../assets/svg/socialmedia/twitter.svg';
// import { ReactComponent as Youtube } from '../../assets/svg/socialmedia/youtube.svg';
import { ReactComponent as Discord } from '../../assets/svg/socialmedia/discord.svg';
import { ReactComponent as Telegram } from '../../assets/svg/socialmedia/telegram.svg';
import { tablet } from '../../styles/breakpoints';
import { useQuery } from '../../styles/breakpoints';
import { linksData } from '../navigation/Navbar';

export const socialMediaLinks = [
	{
		img: <Facebook />,
		href: 'https://www.facebook.com/Aiegochat-106034479131728',
	},
	{
		img: <Instagram />,
		href: 'https://instagram.com/aiegochat?igshid=YmMyMTA2M2Y=',
	},
	{
		img: <LinkedIn />,
		href: 'https://www.linkedin.com/company/ego-chat/',
	},
	{
		img: <Twitter />,
		href: 'https://twitter.com/aiegochat?s=21',
	},
	// {
	// 	img: <Youtube />,
	// 	href: '/',
	// },
	{
		img: <Discord />,
		href: 'https://discord.com/invite/Mj8HDHCckK',
	},
	{
		img: <Telegram />,
		href: 'https://t.me/AI_ego',
	},
];

const FooterStyles = styled.footer`
	background: #FFEAEA;
	& > div > div:first-of-type {
		//top
		& > svg {
			@media ${tablet} {
				margin: 0 auto 30px;
			}
		}
		& > div {
			@media ${tablet} {
				flex-direction: column;
			}
		}
	}
	& > div > div:last-of-type {
		//bottom
		& > div:first-of-type {
			//about us
			max-width: 300px;
			@media ${tablet} {
				margin: 0 auto;
				text-align: center;
			}
		}
		& > div:last-of-type {
			//all right reserved
			align-self: flex-end;
			font-weight: 500;
			font-size: 16px;
			color: #FF4040;
			@media ${tablet} {
				margin: 30px auto 0;
			}
		}
	}
	@media ${tablet} {
		padding: 20px 0;
	}
`;

const Footer = () => {
	const { isTablet } = useQuery();
	return (
		<FooterStyles>
			<Container>
				<FlexWrapper flexDirection={isTablet && 'column-reverse'}>
					<Logo />
					<FlexWrapper width='100%' justifyContent='center' margin='0 0 20px' textAlign={isTablet && 'center'}>
						{linksData.slice(0, 4).map(({ text, to }) => (
							<HashLink color='var(--red)' fontWeight={700} margin={isTablet ? '8px' : '10px'} key={text} to={to}>
								{text}
							</HashLink>
						))}
					</FlexWrapper>
				</FlexWrapper>
				<FlexWrapper justifyContent='space-between' flexDirection={isTablet && 'column'} >
					<Box>
						<Box margin='0 0 20px'>
							<TextBase margin='0 0 10px' color='#FF4040' fontSize='16px' fontWeight={700}>
								About us
							</TextBase>
							<TextBase color='#444444' fontSize='14px' fontWeight={500}>
								ego simplifies people's lives and enhances productivity by leveraging the integration of artificial intelligence with various products and services.
							</TextBase>
						</Box>

						<Box>
							<TextBase margin='0 0 10px' color='#FF4040' fontSize='16px' fontWeight={700}>
								Contact us
							</TextBase>
							<TextBase color='#444444' fontSize='14px' fontWeight={500}>
								hello@ego.com
							</TextBase>
						</Box>
					</Box>
					<Box margin={isTablet ? '0 0 0 0' : '0 -60px 0 0'}>
						<GridWrapper col={7} colL={3} margin={isTablet ? '0 0 20px 0' : '0 0 20px -120px'}>
							{socialMediaLinks.map(({ href, img }) => <SimpleLinkBox key={href} href={href}>{img}</SimpleLinkBox>)}
						</GridWrapper>
						<Box>ego {new Date().getFullYear()}, All Rights Reserved</Box>
					</Box>
				</FlexWrapper>
			</Container>
		</FooterStyles>
	);
};

export default Footer;

const SimpleLinkBox = styled(SimpleLink)`
	cursor: pointer;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #FFF6F5;
	border-radius: 15px;
	& > svg {
		width: 25px;
		height: 25px;
	}
`
