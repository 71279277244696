import React from 'react';
import styled from 'styled-components';
import { HashLink, SimpleLink } from '../links';
import { ReactComponent as Logo } from '../../assets/svg/landing-logo.svg';
import Button from '../buttons/Button';
import MobNavbar from './MobNavbar';

const StyledNavbarWrap = styled.div`
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 40px;
	transition: all 0s ease-out;
	background: rgba(254, 254, 254, 0.4);
	text-decoration: none;
	font-weight: 300;
`;

const StyledNavbar = styled.nav`
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const linksData = [
	{
		text: 'About ego',
		to: '/#about-ego',
	},
	{
		text: 'Features',
		to: '/#features',
	},
	{
		text: 'How it works',
		to: '/#how-it-works',
	},
	{
		text: 'FAQ',
		to: '/#faq',
	},
];

const Navbar = () => {
	return (
		<>
			{window.innerWidth > 600 ? (
				<StyledNavbarWrap>
					<StyledNavbar>
						<HashLink to='/#hero'>
							<Logo />
						</HashLink>
						{
							<div>
								{linksData.map(({ text, to }) => (
									<HashLink color='#BA0101' fontWeight={600} margin='10px' key={text} to={to}>
										{text}
									</HashLink>
								))}
								{/* <Button>Demo</Button> */}
							</div>
						}
					</StyledNavbar>
				</StyledNavbarWrap>
			) : (
				<MobNavbar />
			)}
		</>
	);
};

export default Navbar;
