import React from "react";
import { HashLink as _HashLink } from "react-router-hash-link";
import styled from "styled-components";

const HashLinkStyles = styled(_HashLink)`
    color: ${({ color }) => color || ""};
    margin: ${({ margin }) => margin || ""};
    padding: ${({ padding }) => padding || ""};
    font-weight: ${({ fontWeight }) => fontWeight || "300"};
`

export const HashLink = ({ to, children, ...rest }) => <HashLinkStyles to={to} {...rest}>{children}</HashLinkStyles>