import React from 'react'
import styled from 'styled-components'


const SimpleLinkStyles = styled.a`
    color: ${({ color }) => color || ""};
    margin: ${({ margin }) => margin || ""};
    padding: ${({ padding }) => padding || ""};
    width: ${({ width }) => width || ''};
    font-weight: ${({ fontWeight }) => fontWeight || "300"};
`

export const SimpleLink = ({ href, children, ...rest }) => (
    <SimpleLinkStyles href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
    </SimpleLinkStyles>
)
