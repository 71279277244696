import React, { useRef, useEffect } from 'react';

const AnimationVideo = ({ loop, srcSafari, srcChrome, width, height, ...rest }) => {
	const videoRef = useRef(null);
	useEffect(() => {
		const video = videoRef.current;
		if (video) {
			video.playbackRate = 1;
		}
	}, [videoRef]);

	return (
		<video
			{...rest}
			ref={videoRef}
			preload='auto'
			disableRemotePlayback={true}
			width={width}
			height={height}
			loop
			playsInline
			autoPlay
			muted>
			<source src={srcSafari} type='video/mp4; codecs="hvc1"' />
			<source src={srcChrome} type='video/webm' />
		</video>
	);
};

export default AnimationVideo;
