import React from 'react';
import styled from 'styled-components';
import { SectionWrapper, Container, Box } from '../../components/wrappers';
import { desktop, mobile, tablet } from '../../styles/breakpoints';
import SubscribeForm from '../../components/SubscribeForm';
import TitleTypingEffect from '../../components/TitleTypingEffect';

const SignUpStyles = styled(SectionWrapper)`
	width: 100%;
	padding: 50px 0 100px;
	margin: 0 auto;

	@media ${tablet} {
		margin-top: 100px;
		padding: 50px 0;
		h3 {
			margin-bottom: 30px;
		}
	}
	@media ${mobile} {
		margin-top: 0;
	}
`;

export const TypingEffectBox = styled(Box)`
	margin: 0 auto;
	width: 675px;
	height: 74px;
	margin-bottom: 100px;

	@media ${desktop} {
		width: 450px;
		height: 40px;
		margin-bottom: 70px;
	}
	@media ${mobile} {
		margin: 0 auto;
		width: 345px;
		margin-bottom: 50px;
	}		
`

const SighUp = () => {

	return (
		<SignUpStyles>
			<Container>
				<TypingEffectBox >
					<TitleTypingEffect text='Join the waitlist to access ego' />
				</TypingEffectBox>
				<SubscribeForm />
			</Container>
		</SignUpStyles>
	);
};

export default SighUp;
