import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const InputStyles = styled.input`
  display: inline-block;
  border: none;
  outline: none;
  background: none;
  width: 100%; /* Adjust the width as needed */
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 5px;
  font-size: 14px;
`;

const InputTypingEffect = ({ text, setFinished, pause, className }) => {
    const [index, setIndex] = useState(0);
    const [typingText, setTypingText] = useState('');
    const inputRef = useRef()

    useEffect(() => {
        if (typeof text !== 'string') {
            setTypingText(text);
            setFinished(true);
            return;
        }

        if (pause) return;

        const timerId = setInterval(() => {
            if (index < text.length) {
                setTypingText((prevText) => {
                    let newText = prevText + text.charAt(index);
                    const inputWidth = inputRef.current.clientWidth;

                    inputRef.current.value = newText;

                    while (inputRef.current.scrollWidth > inputWidth) {
                        newText = newText.slice(1);
                        inputRef.current.value = newText;
                    }

                    return newText;
                });
                setIndex((prevIndex) => prevIndex + 1);
            } else {
                setFinished(true);
                clearInterval(timerId);
            }
            if (index < text.length) {
                setFinished(false);
            }
        }, 24);

        return () => clearInterval(timerId);
    }, [index, text, pause]);


    useEffect(() => {
        if (!pause) {
            setFinished(false);
        }
    }, [pause]);

    if (!text.length) return <p></p>;

    return (
        <InputStyles
            readOnly
            ref={inputRef}
            index={index}
            value={typingText}
            className={className}
        />
    );
};

export default InputTypingEffect;
