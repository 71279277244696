import { useMediaQuery } from 'react-responsive';

export const smMobile = '(max-width: 24rem)'; //384px
export const mobile = '(max-width: 30rem)'; //480px
export const navbarDesktop = '(max-width: 600px)'; //600px
export const tablet = '(max-width: 868px)'; //768px
export const smDesktop = '(max-width:64rem)'; //1024px
export const desktop = '(max-width: 75rem)'; //1200px

export const useQuery = () => ({
	isSmMobile: useMediaQuery({ query: smMobile }),
	isMobile: useMediaQuery({ query: mobile }),
	isTablet: useMediaQuery({ query: tablet }),
	isSmDesktop: useMediaQuery({ query: smDesktop }),
	isDesktop: useMediaQuery({ query: desktop }),
	isNavbarDesktop: useMediaQuery({ query: navbarDesktop }),
});
