import { useState } from 'react';
import styled from 'styled-components';
import { mobile } from '../styles/breakpoints';
import { ReactComponent as Plus } from '../assets/svg/plus-circle.svg'
import { ReactComponent as Minus } from '../assets/svg/minus-circle.svg'
import { FlexWrapper } from './wrappers';
// import { ReactComponent as IconPlus } from '../assets/plus.svg'

const AccordionWrap = styled(FlexWrapper)`
	margin-bottom: 10px;
	& > div:first-of-type {
		width: 40px;
		height: 40px;
		padding: 8px;
		border-radius: 15px;
		margin-right: 20px;
		background-color: #FFF6F5;
		cursor: pointer;
		@media ${mobile} {
			margin-right: 10px;
		}
	}
	& > div:last-of-type {
		background-color: ${({ open }) => open && '#FFF6F5'};
		border-radius: 15px;
		& > div {
		&:first-child {
			margin-top: 5px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: var(--red);
			padding: 5px 15px;
			font-weight: 700;
			font-size: 20px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			cursor: pointer;
			svg {
				width: 15px;
				min-width: 15px;
				transition: 0.5s all;
				fill: #d7d0c6;
			}
			@media ${mobile} {
				font-size: 16px;
			}
		}
		&:last-child {
			padding: 5px 15px;
			transition: all 0.7s;
			color: var(--subtext);
			font-size: 16px;
			line-height: 24px;
			font-weight: 300;
			${({ open }) =>
		open
			? `
                max-height: 400px;
                opacity: 1;
            `
			: `
                max-height: 0;
                overflow: hidden;
                opacity: 0;
            `}
				@media ${mobile} {
					font-size: 14px;
				}
			}
		}
	} 	
`;

const Accordion = ({ title, content, children }) => {
	const [open, setOpen] = useState(false);

	return (
		<AccordionWrap onClick={() => setOpen(!open)} open={open}>
			<div>
				{!open ? <Plus /> : <Minus />}
			</div>
			<div>
				<div>{title}</div>
				<div>{content || children}</div>
			</div>
		</AccordionWrap>
	);
};

export default Accordion;
